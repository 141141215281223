import useAppContext from 'app/store/useAppContext'
import { setIsSuccessAffirmationModal } from 'app/store/actions'
import { Button } from 'shared/ui/button'
import { Modal } from 'shared/ui/modal'
import CheckModalIcon from 'shared/assets/icons/ok.webp'

import cls from './AcceptModal.module.scss'

export default function AcceptModal() {
  const {
    store: {
      modals: { isSuccessAffirmationModal },
    },
    dispatch,
  } = useAppContext()

  const closeModal = () => {
    dispatch(setIsSuccessAffirmationModal(false))
  }
  const onAccept = () => {
    closeModal()
  }

  return (
    <>
      <Modal
        isOpen={isSuccessAffirmationModal}
        onRequestClose={closeModal}
        content={
          <div className={cls.modal__content}>
            <img src={CheckModalIcon} alt="accept" />
            <h2 className={cls.modal__title}>
              Ваша заявка уже была принята в работу!
            </h2>
            <p className={cls.modal__desc}>
              Вы уже оставили заявку через нашего официального бота. Ожидайте
              звонка помощника в течение рабочего дня.
            </p>
            <Button className={cls.button} onClick={onAccept}>
              Продолжить
            </Button>
          </div>
        }
      />
    </>
  )
}
